import axios from 'axios';
import React, { useReducer, useState, useEffect } from 'react';
import useInterval from "./../hooks/useInterval";
import Share from "./Share";


export default function AuthControllerWindy(props) {

    const isMob = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const [windowInitW, setWindowInitW] = useState(window.innerWidth);

    const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

    const [isNavbarOpen, setNavbarOpen] = useState(true);

    const [isHovered, setIsHovered] = useState(false);

    const [mappaWindyProps, setMappaWindyProps] = useState(props.mappaWindyProps);

    let touchStartTime;
    

    useEffect(() => {
        const handleOrientationChange = (event) => {
            //console.log("isPortrati : " + event.matches);
            if(event.matches){ // portrait
                const controlPanel = document.querySelector('.ControlPanelLateraleResponsive');
                if(controlPanel){
                    controlPanel.style.marginTop = '0px';
                }
            }
            else{ // landscape
                const controlPanel = document.querySelector('.ControlPanelLateraleResponsive');
                if(controlPanel){
                    controlPanel.style.marginTop = '0px';
                }
            }
            //console.log("orientation change");
          setIsPortrait(event.matches);
        };
    
        const mediaQuery = window.matchMedia("(orientation: portrait)");
        mediaQuery.addEventListener("change", handleOrientationChange);
    
        return () => {
          mediaQuery.removeEventListener("change", handleOrientationChange);
        };
      }, []);

    const _logga = (...any) => {
        // console.log("[htk][login]", ...any);
    }

    const logga = props.logger || _logga;

    let _csrfCookie = null;

    const [currentUser, setCurrentUser] = React.useState(
        JSON.parse(sessionStorage.getItem('loggedUser'))
    );

    const delay = 2000;

    const apiClient = axios.create({
        baseURL: props.apibase,
        withCredentials: true,
    })

    const userSave = (userData) => {
        
        _logga("userdata found:", userData);
        let curUser = null;
        
        if (userData) {
            _logga("current user:", userData.email);
            curUser = {
                email: userData.email,
                name: userData.name,
                surname: userData.surname,
                completeName: userData.name + " " + userData.surname
            };
            props._fnUserTipo(userData.tipo);
        }
        
        if (curUser != currentUser) {
            _logga("Login status changed to", curUser)
            props.onLoginToggle(curUser);
            //props.onUserChange(curUser); // passaggio del valore dell'utente alla componente padre
            setCurrentUser(curUser)
            sessionStorage.setItem('loggedUser', JSON.stringify(curUser));
        }
    }

    const _userSessionCheck = () => {
        logga("Checking user session")

        if (props.accessMode) {
            logga("Utente guest code");
            userSave({
                email: 'guest@hightek.it',
                name: 'Guest'
            });
            return;
        }

        // controllare se viene forzato il render a cause di useState
        apiClient.get('/testing/json/user')
            .then((response) => {
                if (response.status !== 200) {
                    throw "user session not found";
                }

                logga("Utente loggato:", response.data.email)
                userSave(response.data)
            })
            .catch((response) => {
                logga("Errore: ", response);
                userSave(null);
            })
    }

    const _tokenGet = async () => {
        if (!_csrfCookie) {
            logga("CSRF Token not set, searching in cookies..")
            _csrfCookie = document.cookie
                .split('; ')
                .find(row => row.startsWith('XSRF-TOKEN='))
        }

        if (!_csrfCookie) {
            logga("CSRF Cookie not found, requesting CSRF Token", `${props.apibase}/sanctum/csrf-cookie`)
            apiClient.get('/sanctum/csrf-cookie').then(() => {
                logga("Setting cookie");
                _tokenGet();
            }).catch(err => {
                logga("Error obtaining token", err);
            })
            return
        }

        logga("_csrfCookie is", _csrfCookie.split('=')[1])
    }

    const loginLaunch = (e) => {
        _tokenGet()
        // _logga("login api base", props.apibase, props.apibase.trimEnd('/'));
        const loginPage = props.apibase.replace(/\/+$/, '') + `/login?htk_redirect=${window.location.href}`;
        window.location.href = loginPage;
    }

    function handleTouchStart() {
        touchStartTime = new Date().getTime();
    }

    function handleTouchEnd(event) {
        const touchEndTime = new Date().getTime();
        const touchDuration = touchEndTime - touchStartTime;
        
        if (touchDuration < 200) { // Tempo massimo per considerare un tocco come clic
            //console.log("Tocco eseguito");
            onTouchMissions(event);
        }
    }

    function handleTouchStartUser() {
        touchStartTime = new Date().getTime();
    }

    function handleTouchEndUser(event) {
        const touchEndTime = new Date().getTime();
        const touchDuration = touchEndTime - touchStartTime;
        
        if (touchDuration < 200) { // Tempo massimo per considerare un tocco come clic
            //console.log("Tocco eseguito");
            onTouchMissionsUser(event);
        }
    }

    const onTouchMissions = (event) => {
        event.preventDefault();
        //console.log("NUMERO CLICK")
        window.open(props.htkBackend + "/missioni");
      };

      const onTouchMissionsUser = (event) => {
        event.preventDefault();
        //console.log("NUMERO CLICK")
        window.open(props.assetsBase + "/");
      };
        
    useInterval(_userSessionCheck, delay);

    /*
    const closePanel = () => {
        const navbar = document.querySelector('.barraNavigazioneWindy');
        navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
        const altezzaNavbar = navbar.offsetHeight;
        navbar.style.marginTop = `-${altezzaNavbar}px`;

        setTimeout(() => {
            const bottone = document.querySelector('.contenitoreIcona2');
            bottone.classList.remove('invisible');
            bottone.classList.add('visible');
        }, 410);
    }

    const openPanel = () => {
        const navbar = document.querySelector('.barraNavigazioneWindy');
        navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
        navbar.style.marginTop = '0px';

        const bottone = document.querySelector('.contenitoreIcona2');
        bottone.classList.remove('visible');
        bottone.classList.add('invisible');

        const controlPanel = document.querySelector('.ControlPanelLaterale');
        controlPanel.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
        controlPanel.style.marginTop = '0px';
    }
*/

    const closePanelResp = () => {
        const navbar = document.querySelector('.barraNavigazioneWindy');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            const altezzaNavbar = navbar.offsetHeight;
            navbar.style.marginTop = `-${altezzaNavbar}px`;
        }

        setTimeout(() => {
            const bottone = document.querySelector('.contenitoreIcona2Resp');
            if(bottone){
                bottone.classList.remove('invisible');
                bottone.classList.add('visible');
            }
        }, 50);
    }


    const openPanelResp = () => {
        const navbar = document.querySelector('.barraNavigazioneWindy');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            navbar.style.marginTop = '0px';
        }

        const bottone = document.querySelector('.contenitoreIcona2Resp');
        if(bottone){
            bottone.classList.remove('visible');
            bottone.classList.add('invisible');
        }
    }

    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };

      function resizeWindow() {
        const resizeEvent = window.document.createEvent("UIEvents");
        resizeEvent.initUIEvent("resize", true, false, window, 0);
        window.dispatchEvent(resizeEvent);
    }

      const cambiaMappa = () => {

        const mappaEarth = document.querySelector('gmp-map-3d');

        if (mappaEarth) {
            const center = mappaEarth.getAttribute('center');
            const range = mappaEarth.getAttribute('range');
            
            // const bounds = mappaEarth.getAttribute('bounds');
            // console.log("bounds google 3d : " + bounds);

            if (center) {
                const [lat, lng, altitude] = center.split(',').map(Number);
               //console.log(`Parametri dalla mappa Google (Center): lat=${lat}, lng=${lng}, altitude=${altitude}, range=${range}`);

                let rangeNum = parseFloat(range);

                // definire una mappatura al contrario che , partendo da altitude/range ricavi lo zoom

                const rangeToZoom = [
                    { range: 66874361, zoom: 0},
                    { range: 33074361, zoom: 1},
                    { range: 16874361, zoom: 2},
                    { range: 8874361, zoom: 3},
                    { range: 7437177, zoom: 4},
                    { range: 4718585, zoom: 5},
                    { range: 2359289, zoom: 6},
                    { range: 1179641, zoom: 7},
                    { range: 589817, zoom: 8},
                    { range: 294905, zoom: 9},
                    { range: 147449, zoom: 10},
                    { range: 73721, zoom: 11},
                    { range: 35857, zoom: 12},
                    { range: 18425, zoom: 13},
                    { range: 9209, zoom: 14},
                    { range: 4601, zoom: 15},
                    { range: 2297, zoom: 16},
                    { range: 1145, zoom: 17},
                    { range: 569, zoom: 18},
                    { range: 281, zoom: 19},
                    { range: 137, zoom: 20},
                    { range: 65, zoom: 21},
                    { range: 29, zoom: 22}
                ];

                function generateRangeIntervals(rangeToZoom) {
                    const newZoomToRange = [];
                    const steps = 10;
                
                    for (let i = 0; i < rangeToZoom.length - 1; i++) {
                        const current = rangeToZoom[i];
                        const next = rangeToZoom[i + 1];
                
                        const zoomStep = (next.zoom - current.zoom) / steps;
                        const rangeStep = (next.range - current.range) / steps;
                
                        for (let j = 0; j < steps; j++) {
                            newZoomToRange.push({
                                range: parseFloat((current.range + rangeStep * j).toFixed(1)),
                                zoom: parseFloat((current.zoom + zoomStep * j).toFixed(1))
                            });
                        }
                    }
                
                    // Add the last item
                    newZoomToRange.push(rangeToZoom[rangeToZoom.length - 1]);
                
                    return newZoomToRange;
                }
                
                const newRangeToZoom = generateRangeIntervals(rangeToZoom);
                //console.log("NEW RANGE : " + JSON.stringify(newRangeToZoom));

                function getLeafletZoomFromRange(range) {
                    // Cerca il range corrispondente
                    for (let i = 0; i < newRangeToZoom.length; i++) {
                        if (range >= newRangeToZoom[i].range) {
                            return newRangeToZoom[i].zoom;
                        }
                    }
                    // Se il range è più piccolo del minimo valore nella mappatura, ritorna il massimo livello di zoom
                    return newRangeToZoom[newRangeToZoom.length - 1].zoom;
                }

                // let zoomLevel = getZoomLevelForRange(altitudeRange.toFixed(0));
                let zoomLevel = getLeafletZoomFromRange(rangeNum.toFixed(0));

               //console.log("Zoom Level calcolato di conseguenza:", zoomLevel);

               //console.log("FINE LOG GOOGLE.........");

                // Verifica che `props.map` sia definito e quindi usa `map` per aggiornare la vista della mappa
                if (props.mappaLeaflet) {
                    props.mappaLeaflet.setView(new L.LatLng(lat, lng), zoomLevel);
                } else {
                    console.error("Mappa Leaflet non definita.");
                }

            } else {
                console.error("Attribute 'center' is not defined.");
            }
        } else {
            console.error("Map object is not defined.");
        }

        const controlPanelWindy = document.querySelector(".ControlPanelWindy");
        if(controlPanelWindy){
            controlPanelWindy.style.setProperty("display", "none", "important");
        }
        const infoBoxWindy = document.querySelector(".FlyInfoPanelContainerWindy");
        if(infoBoxWindy){
            infoBoxWindy.style.setProperty("display", "none", "important");
        }

        const authHeaderWindy = document.querySelector(".barraNavigazioneWindy");
        authHeaderWindy.style.setProperty("display", "none", "important");

        const authHeaderGoogle = document.querySelector(".barraNavigazione");
        authHeaderGoogle.style.display = "block";


        // cambio mappa

        const mappaGoogle = document.querySelector(".mappaGoogle");
        mappaGoogle.style.display = "block";

        const mappaWindyStyle = document.getElementById("mappaGoogle3D");
        mappaWindyStyle.style.display = "none";

        //

        props.setMappaLeafletProps();

    };

      const openPanelWindy = () => {
        //console.log("open panel authWindy.js");

        const navbar = document.querySelector('.barraNavigazioneWindy');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            navbar.style.marginTop = '0px';
        }

        const bottone = document.querySelector('.contenitoreIcona2Windy');
        if(bottone){
            bottone.classList.remove('visible');
            bottone.classList.add('invisible');
        }
    }
    
    const closePanelWindy = () => {
        //console.log("close panel authWindy.js");

        const navbar = document.querySelector('.barraNavigazioneWindy');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            const altezzaNavbar = navbar.offsetHeight;
            navbar.style.marginTop = `-${altezzaNavbar}px`;
        }

        setTimeout(() => {
            const bottone = document.querySelector('.contenitoreIcona2Windy');
            if(bottone){
                bottone.classList.remove('invisible');
                bottone.classList.add('visible');
            }
        }, 10);
    }

    if (currentUser) {
        // return (<h1>Benvenuto {currentUser.email}</h1>);

        return (
            <>
                {!isMob  && //isNavbarOpen && Mostra la barra dell'intestazione solo su schermi larghi o in modalità landscape su dispositivi mobili
                    (
                        <>
                        <nav className="navbar navbar-expand-md navbar-light bg-white shadow-sm navBarSystem barraNavigazioneWindy" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <div className="d-flex flex-row align-items-center w-100 container position-relative">
                                <div className="">
                                <img src={props.htkBackend + "/brand/QWEB.png"} width="40" alt="Logo" />
                                </div>
                                <div className="ms-auto ">
                                <div className="d-flex flex-row p-2 align-items-center">

                                    {/* <button id="mioPulsante" onClick={cambiaMappa}>Google</button> */}

                                    <img className="" src="/brandIcons/GPS_POSITION.png" style={{ height: '40px', width: '40px' }} alt="Mission Icon" />
                                    <a className="btn btn-gray" target="_blank" onClick={cambiaMappa}>Leaflet</a>

                                    <img className="" src="/brandIcons/MISSIONI.png" style={{ height: '40px', width: '40px' }} alt="Mission Icon" />
                                    <a className="btn btn-gray" target="_blank" href={props.htkBackend + "/missioni"}>Missions</a>

                                    <img className="" src="/brandIcons/USERS.png" style={{ height: '40px', width: '40px' }} alt="User Icon" />
                                    <a className="btn btn-gray" target="_blank" href={props.assetsBase + "/"}>{currentUser.completeName}</a>
                                </div>
                                </div>
                            </div>
                            <div className={`divContainerButtSeleziona2 position-absolute start-50 translate-middle contenitoreIconaWindy ${isHovered ? 'visible' : 'invisible'}`} style={{ bottom: '0', transform: 'translateX(-31px)' }} onClick={closePanelWindy}>
                                    <div className="fas fa-2x fa-chevron-up iconaFreccia"></div>
                            </div>
                        </nav>
                        <div className="divContainerButtSeleziona2 position-absolute start-50 translate-middle contenitoreIcona2Windy invisible" style={{top: '0', transform: 'translateX(-16px)' , zIndex: '10000000000000'}} onClick={openPanelWindy}>
                            <div className="fas fa-2x fa-chevron-down iconaFreccia2"></div>
                        </div>
                        </>
                    ) 
                }

                {isMob && isPortrait && // Mostra la barra dell'intestazione solo su schermi stretti e in modalità portrait su dispositivi mobili
                                (
                                    <>
                                    <nav className="navbar navbar-light bg-white shadow-sm navBarSystemPortrait barraNavigazioneWindy">
                                        <div className="d-flex flex-row align-items-center container">

                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <img src={props.htkBackend + "/brand/QWEB.png"} className="imgQwebHeader1"/>
                                                </div>
                                                
                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <img className="imgQwebHeader" src="/brandIcons/MISSIONI.png"  /> 
                                                    <a className="btn btn-gray" target="_blank" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>Missions</a>
                                                </div>
                                                
                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <img className="imgQwebHeader" src="/brandIcons/USERS.png" /> 
                                                    <a className="btn btn-gray" target="_blank" onTouchStart={handleTouchStartUser} onTouchEnd={handleTouchEndUser}>{currentUser.completeName}</a>
                                                </div>
                                        </div>
                                        <div className="divContainerButtSeleziona2 position-absolute start-50 translate-middle contenitoreIconaResp dimensioniIconaApri" style={{ bottom: '0', transform: 'translateX(-31px)' }} onTouchEnd={closePanelResp}>
                                            <div className="fas fa-1x fa-chevron-up iconaFrecciaResp"></div>
                                        </div>
                                    </nav>
                                    <div className="divContainerButtSeleziona2 position-absolute start-50 translate-middle contenitoreIcona2Resp invisible dimensioniIconaChiudi" style={{top: '0', transform: 'translateX(-16px)' }} onTouchEnd={openPanelResp}>
                                        <div className="fas fa-1x fa-chevron-down iconaFreccia2Resp"></div>
                                    </div>
                                    </>
                                ) 
                }

            {isMob && !isPortrait /*&& // Mostra la barra dell'intestazione solo su schermi stretti e in modalità portrait su dispositivi mobili
                                (
                                    <>
                                    <nav className="navbar navbar-light bg-white shadow-sm navBarSystemPortrait barraNavigazioneWindy">
                                        <div className="d-flex flex-row align-items-center container">

                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <img src={props.htkBackend + "/brand/QWEB.png"} className="imgQwebHeader1"/>
                                                </div>
                                                
                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <img className="imgQwebHeader" src="/brandIcons/MISSIONI.png"  /> 
                                                    <a className="btn btn-gray" target="_blank" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>Missions</a>
                                                </div>
                                                
                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <img className="imgQwebHeader" src="/brandIcons/USERS.png" /> 
                                                    <a className="btn btn-gray" target="_blank" onTouchStart={handleTouchStartUser} onTouchEnd={handleTouchEndUser}>{currentUser.completeName}</a>
                                                </div>
                                        </div>
                                        <div className="divContainerButtSeleziona2 position-absolute start-50 translate-middle contenitoreIconaResp dimensioniIconaApri" style={{ bottom: '0', transform: 'translateX(-31px)' }} onTouchEnd={closePanelResp}>
                                            <div className="fas fa-1x fa-chevron-up iconaFrecciaResp"></div>
                                        </div>
                                    </nav>
                                    <div className="divContainerButtSeleziona2 position-absolute start-50 translate-middle contenitoreIcona2Resp invisible dimensioniIconaChiudi" style={{top: '0', transform: 'translateX(-16px)' }} onTouchEnd={openPanelResp}>
                                        <div className="fas fa-1x fa-chevron-down iconaFreccia2Resp"></div>
                                    </div>
                                    </>
                                ) */
                }

            </>
        )
    } else {
        return (
                <>
                    <nav className="navbar navbar-expand-md navbar-light bg-white shadow-sm navBarSystem">
                        <div className="d-flex flex-row align-items-center w-100 container">
                            <div className=""><img src={props.assetsBase + "/brand/QWEB.png"} width="40" /></div>
                            <div className="ms-auto d-flex flex-row">
                                <h5>Per utilizzare q-live è necessario autenticarsi</h5>
                                <button type="button" onClick={loginLaunch}>
                                    Autenticami
                                </button>
                            </div>
                        </div>
                    </nav>
                </>
        )
    }
}