// src/Map3DComponent.js
import React, { useEffect } from 'react';

const Map3DComponent = () => {
  useEffect(() => {
    // Aggiungi lo script della mappa al documento
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBS4-irfJhThd3xzM0cb1fFMKWMSA3pQ_g&v=alpha&libraries=maps3d';
    script.async = true;
    script.onload = () => {
      // console.log('Google Maps API script loaded');
    };
    document.body.appendChild(script);

    // Cleanup function per rimuovere lo script quando il componente viene smontato
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <gmp-map-3d center="37.841157, -122.551679" tilt="67.5" style={{ width: '100%', height: '100vh' }}></gmp-map-3d>
    </div>
  );
};

export default Map3DComponent;
