import React, { useRef, useState, useEffect } from 'react';
import Hls from 'hls.js';

const VideoPlayerHls = ({ videoStreaming, nomeDispositivo, videoStreamingName }) => {
  const videoRef = useRef(null);
  const [videoSrc, setVideoSrc] = useState('');
  const [reloadTrigger, setReloadTrigger] = useState(false);

  useEffect(() => {
    const updateVideoSrc = () => {
      const newVideoSrc = `${videoStreaming}/${nomeDispositivo}/${videoStreamingName}/index.m3u8`;
      setVideoSrc(newVideoSrc);
    };

    updateVideoSrc();
  }, [videoStreaming, nomeDispositivo, videoStreamingName]);

  useEffect(() => {
    const initializeHls = () => {
      const hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          console.error('Errore fatale durante il caricamento del video:', data.type, data.details);
          
          // Verifica se l'errore non è di tipo "manifestParsingError"
          if (data.type !== Hls.ErrorTypes.NETWORK_ERROR || data.details !== Hls.ErrorDetails.MANIFEST_PARSING_ERROR) {
            // Attiva il trigger di ricarica per aggiornare la componente
            setTimeout(() => setReloadTrigger(prevState => !prevState), 3000); // Ricarica ogni 3 secondi
          }
        }
      });

      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        if (videoRef.current) {
          videoRef.current.play();
        }
      });
    };

    initializeHls();

    const handleLeavePictureInPicture = () => {
      if (videoRef.current) {
        videoRef.current.play(); // Forza la riproduzione del video
      }
    };

    const videoElement = videoRef.current;
    videoElement.addEventListener('leavepictureinpicture', handleLeavePictureInPicture);

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('leavepictureinpicture', handleLeavePictureInPicture);
      }
    };
  }, [videoSrc, reloadTrigger]); // Aggiungi reloadTrigger come dipendenza

  return (
    <video
      autoPlay
      controls
      ref={videoRef}
      className="custom-video-player-pc"
      key={reloadTrigger} // Forza il re-rendering del componente quando il trigger di ricarica viene attivato
    ></video>
  );
};

export default VideoPlayerHls;
