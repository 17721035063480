import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import css from './App.css';
import reportWebVitals from './reportWebVitals';
import serialsJson from './data/serials.json';

const HTK_BACKEND = process.env.REACT_APP_HTK_BACKEND || window._env_.REACT_APP_HTK_BACKEND;
const HTK_ASSETS = process.env.REACT_APP_HTK_ASSETS || window._env_.REACT_APP_HTK_ASSETS;
const HTK_CDN = process.env.REACT_APP_HTK_CDN || window._env_.REACT_APP_HTK_CDN;
// const HTK_CDN_SIMU = process.env.REACT_APP_HTK_CDN_SIMU || window._env_.REACT_APP_HTK_CDN_SIMU;

// const HTK_BACKEND = "https://qweb.hightek.it/";
// const HTK_ASSETS = "https://qcdn.hightek.it/";
// const HTK_CDN = "https://qcdn.hightek.it/v2/";
// const HTK_CDN_SIMU = "https://185.203.25.236:81/simu/v2/";
// const HTK_BACKEND = "http://qcenter.hightek.local:3500";
// const HTK_CDN = "http://185.203.25.236:81/simu/v2/";
// htkCDNSimu={HTK_CDN_SIMU}

ReactDOM.render(
  <React.StrictMode>
    <App
      sns={[]}
      htkCDN={HTK_CDN}

      htkBackend={HTK_BACKEND}
      htkAssets={HTK_ASSETS}
      htkSimu = "SIMU-P020001/history.json"
      htkSimuSerial = ""
      snsJson = {serialsJson} timePolling={1000}
      startPosition = {[40.783712,14.698841]}
      htkBackendEmail={"https://qweb.hightek.it/sendMailQLive"}
      accessMode = {false}
      
      /* ------------------------------------------------------------------*/
                      /*----- LINK STREAMING -----*/
      htkVideoStreamingH265={"https://dev-livestreaming.hightek.it/hightek"}                                        // ATTUALE
      htkVideoStreamingH264={"https://dev-livestreaming.hightek.it/h264/hightek"}                                   // ATTUALE

      // url streaming H265
      htkStreamingProduzioneH265={"https://livestreaming.hightek.it/hightek"}
      htkStreamingDevH265={"https://dev-livestreaming.hightek.it/hightek"}

      // url streaming H264
      htkStreamingProduzioneH264={"https://livestreaming.hightek.it/h264/hightek"}
      htkStreamingDevH264={"https://dev-livestreaming.hightek.it/h264/hightek"}
      
      /* ------------------------------------------------------------------*/
                      /*----- FILTRAGGIO SERIALI CON STREAMING -----*/

      // filtraggio tra chi ha lo streaming e chi no
      serialiStreaming = {['AB-Q10000','AA-Q10000','AA-D10000','AA-D10001','AA-QG1000S','AA-QG1001S','AA-QG1002S']}  // ATTUALE

      serialiStreamingDev = {['AB-Q10000','AA-Q10000','AA-D10000','AA-D10001','AA-QG1000S','AA-QG1001S','AA-QG1002S']} 
      serialiStreamingProduzione = {['AA-D10000','AA-D10001','AA-Q10000','AB-Q10000','AB-Q10001','AB-Q10002','AB-Q10003','AB-Q10004','AB-Q10005','AB-Q10006','AB-Q10007','AA-QG1000S','AA-QG1001S','AA-QG1002S','OC-10042']} 

      /* ------------------------------------------------------------------*/
                      /*----- FILTRAGGIO STREAMING H265/H264 -----*/

      // filtraggio tra chi ha lo streaming H265 e chi ha lo streaming H264
      streamingH265={['AA-QG1000S','AA-QG1001S','AA-QG1002S','AA-D10001']}                                           // ATTUALE
      streamingH264={['AA-D10000','AA-Q10000','AB-Q10000']}                                              // ATTUALE

      // filtraggio tra chi ha lo streaming H265 e chi ha lo streaming H264 (dev)
      streamingH265dev={['AA-QG1000S','AA-QG1001S','AA-QG1002S']}
      streamingH264dev={['AA-D10000','AA-D10001','AA-Q10000','AB-Q10000']} // AA-P020000 Elicentro non ha streaming

      // filtraggio tra chi ha lo streaming H265 e chi ha lo streaming H264 (produzione)
      streamingH265prod={['AA-QG1000S','AA-QG1001S','AA-QG1002S','AA-D10000']}
      streamingH264prod={['AA-D10001','AA-Q10000','AB-Q10000','AB-Q10001','AB-Q10002','AB-Q10003','AB-Q10004','AB-Q10005','AB-Q10006','AB-Q10007','OC-10042']} // AA-P... non hanno lo streaming

      /* ------------------------------------------------------------------*/

    />
  </React.StrictMode>,
  document.getElementById('root')
);

// AA-0242ac1e0003
// AA-001e06422518
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
