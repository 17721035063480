import React from "react";
import ConnectionState from "./ConnectionState";
import Telemetria from "./telemetria";
import Media from './Media';
import {useState, useEffect} from "react";
import {useMap} from "react-leaflet";
import "../InfoBox.css";
import Moment from 'react-moment';
import 'moment/locale/it';
import useInterval from "../hooks/useInterval";
import axios from 'axios';
import Infoboxtitle from "./infoboxtitle";
import BigMedia from "./bigMedia";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import VideoPlayerHls from "./VideoPlayerHls";

export default function InfoBox(props) {

    const [infoBoxId, setInfoboxId] = useState(props.index);
    const videoPlayerKey = props.videoPlayerKey || 0; // Chiave unica per forzare il re-render della componente VideoPlayerStreamingTest

    //const [videoStreamingContainer, setVideoStreamingContainer] = useState("videoStreamingContainer");
    const [videoStreamingH265, setVideoStreamingH265] = useState(props.urlVideoStreamingH265);
    const [videoStreamingH264, setVideoStreamingH264] = useState(props.urlVideoStreamingH264);

    const [node, setNode] = useState(props.singleSnData);
    const [videoStreamingName, setVideoStreamingName] = useState(node?.point?.info?.MISSION_NUM);

    const [imagePoint, setImagePoint] = useState(null);
    const [isToggled, setToggled] = useState(false);
    const [iconLinkStatus, setIconLinkStatus] = useState("fa-chevron-down");
    const [classStatePanel, setClassStatePanel] = useState("");

    const [linkStatus, changeLinkStatus] = useState("SEARCHING");
    const [linkStatusStreaming, changeLinkStatusStreaming] = useState("SEARCHING");
    const [pdv, setPdv] = useState(null);
    const [allData, setAllData] = useState(props.allData);
    const [seriale, setSeriale] = useState("Seriali ...");
    const serialiStreaming = props.serialiStreaming;
    const streamingH265 = props.streamingH265;
    const streamingH264 = props.streamingH264;

    const [imageData, setImageData] = useState(null);
    const [lastFlyToPosition, setLastFlyToPosition] = useState();
    const [userTipo, setUserTipo] = useState(props.userTipo);
    const [bigMediaPic, setBigMediaPic] = useState(null);
    const [typeBigImage, setTypeBigImage] = useState("IR_IMAGE");
    const [typeImage, setTypeImage] = useState("IR_IMAGE");
    const [videoOpen, setVideoOpen] = useState(false);
    const [photoOpen, setPhotoOpen] = useState(false);

    const [gradiLat, setGradiLat] = useState(node.point.points.LATITUDE);
    const [minutiLat, setMinutiLat] = useState(node.point.points.LATITUDE);
    const [secondiLat, setSecondiLat] = useState(node.point.points.LATITUDE);

    const [gradiLon, setGradiLon] = useState(node.point.points.LONGITUDE);
    const [minutiLon, setMinutiLon] = useState(node.point.points.LONGITUDE);
    const [secondiLon, setSecondiLon] = useState(node.point.points.LONGITUDE);

    const [infoboxStatesStreaming, setInfoboxStatesStreaming] = useState(props.infoboxStatesStreaming);

    const map = useMap();

    const [convertedAngle, setConvertedAngle] = useState(null);

    const [isPressedStreaming, setIsPressedStreaming] = useState(false);

    const flyZoomOptions = {
        animate: true, duration: 0.5
    }

    const apiClient = axios.create({
        baseURL: props.htkBackend,
        withCredentials: true,
    })

    useEffect(() => {
        setInfoboxId(infoBoxId);
      }, [infoBoxId]);

      useEffect(() => {
        setIsPressedStreaming(isPressedStreaming);
      }, [isPressedStreaming]);

      useEffect(() => {
        setInfoboxStatesStreaming(props.infoboxStatesStreaming);
      }, [props.infoboxStatesStreaming]);

    useEffect(() => {
        if (linkStatusStreaming !== 'CONNECTED') {
          //setVideoOpen(false);
          props.setInfoboxStatesStreaming(prevStates => ({
            ...prevStates,
            [props.ALIAS]: false,
          }));
        }
        else{
            // se è connected/searching
            if(isPressedStreaming){
                // console.log("AVEVO CLICCATO IL BOTTONE");
                // se avevo cliccato il bottone
                props.setInfoboxStatesStreaming(prevStates => ({
                    ...prevStates,
                    [props.ALIAS]: true,
                  }));
            }
            else{
                // console.log("NON AVEVO CLICCATO IL BOTTONE");
            }
        }
      }, [linkStatusStreaming]);
    
    useEffect(() => {
        const iframe = document.getElementById('myIframe');
    
        if (iframe) {
          iframe.addEventListener('load', () => {
            const video = iframe.contentDocument.querySelector('video');
            if (video) {
              video.style.fontSize = '20px';
            }
          });
        }
      }, []);
    

    useEffect(() => {
        setUserTipo(props.userTipo);
    }, [props.userTipo]);

    useEffect(() => {
        setVideoStreamingName(node.point.info.MISSION_NUM);
      }, [node.point.info.MISSION_NUM]);

    useEffect(() => {
    }, [props.allData])

    var moment = require('moment-timezone');
    //const dateTimeStamp = moment.utc(node.point.time.YEAR + "-" + node.point.time.MONTH + "-" + node.point.time.DAY + " " + node.point.time.HOUR + ":" + node.point.time.MINUTE + ":" + node.point.time.SECOND).local();
    const dateTimeStamp = moment.utc(`${node.point.time.YEAR}-${node.point.time.MONTH}-${node.point.time.DAY} ${node.point.time.HOUR}:${node.point.time.MINUTE}:${node.point.time.SECOND}`, 'YYYY-MM-DD HH:mm:ss').local();

    const toggleTrueFalse = () => {
        setToggled(!isToggled);
        isToggled ? setClassStatePanel("") : setClassStatePanel("InfoBox-Close");
        
        if (isToggled) {
            setClassStatePanel("");
            setTimeout(() => {
                setIconLinkStatus("fa-chevron-down");
            }, 600);
        } else {
            setClassStatePanel("InfoBox-Close");
            setTimeout(() => {
                setIconLinkStatus("fa-chevron-up");
            }, 1100);
        }
    
    };

    const tooglePhotoPanel = () => {
        setPhotoOpen(!photoOpen);

    }

    useInterval(() => {checkPDV(node.SN)}, 1000);

    const checkPDV = async(sn) => {

        apiClient.get(props.htkBackend + "/datalive/PDV/" + sn)
            .then((response) => {
                if (response.status == 200) {
                    if ( response.data.status == "noPDV") {

                    }  else {
                        setPdv(response.data);
                        setSeriale(response.data.seriale.seriale);
                    }

                }
            })
    };

    useEffect(() => {
        let imageData = allData.filter((singleNode) => {
            if ( singleNode.point.hasOwnProperty("images")) {
                if ( singleNode.point.images.RGB_IMAGE != "") {
                    return true
                } else {
                    return false;
                }
            }
        });
        imageData = imageData.slice(0, 4);
        setImageData(imageData);
    }, [props.allData]);

    useEffect( () => {
        if ( props.infoboxStates[props.ALIAS]) {
            //console.log("EFFETTO QUANDO CAMBIA INFOBOXSTATES[props.ALIAS]");
            map.flyTo([node.point.points.LATITUDE, node.point.points.LONGITUDE],map.getZoom(), flyZoomOptions);
        }
    }, [props.infoboxStates[props.ALIAS]]);

    useEffect( () => {
        props.setInfoboxStates(props.infoboxStates);
    }, [props.infoboxStates]);

    useEffect( () => {
        props.setInfoboxStatesPhoto(props.infoboxStatesPhoto);
    }, [props.infoboxStatesPhoto]);

    useEffect( () => {
        props.setInfoboxStatesStreaming(props.infoboxStatesStreaming);
    }, [props.infoboxStatesStreaming]);


    function flyToCentering()  {
        map.flyTo([node.point.points.LATITUDE, node.point.points.LONGITUDE],map.getZoom(), flyZoomOptions)
    }

    const openImagePop = (nodeImage, typeImage) => {
        setBigMediaPic(nodeImage);
        setTypeBigImage(typeImage);

    }

    /*
    const toogleVideoPanel = () => {
        setVideoOpen(!videoOpen);
    }
    */
    const _eventClosePreview = () => {
        setBigMediaPic(null);
    }

    const _fnTitleInfoBox2 = () => {
        switch (userTipo) {
            case 1:
                return (
                    <h4>{pdv?.alias}</h4>
                )
            case 2:
                return (
                    <div className="font-23">{pdv?.seriale.seriale}/{pdv?.marca}/{pdv?.alias}</div>
                )
            case 3:
                return (
                    <h4>{pdv?.marca}</h4>
                )
            default:
                return (
                    <div>Loading ...</div>
                )
        }
    } ;

    useEffect( () => {
        setNode(props.singleSnData);
        if(node.Direction < 0){
            setConvertedAngle(node.Direction + 360);
        }
        else{
            setConvertedAngle(node.Direction);
        }
        let diffLat = 9999;
        let diffLon = 9999;

        if ( lastFlyToPosition) {
            diffLat = Math.abs(lastFlyToPosition[0]-node.point.points.LATITUDE);
            diffLon = Math.abs(lastFlyToPosition[1]-node.point.points.LONGITUDE);
        }

        if ( props.infoboxStates[props.ALIAS]) {
           //console.log("bottone follow in questione su true...");

            if ( diffLat > 0.0001  || diffLon > 0.0001) {
                //map.flyTo([node.point.points.LATITUDE, node.point.points.LONGITUDE],map.getZoom(), flyZoomOptions);

                //console.log("EFFETTO QUANDO CAMBIA SINGLESNDATA : " , props.singleSnData);
                props.setFlyToLeaflet(node.point.points.LATITUDE, node.point.points.LONGITUDE);
                setLastFlyToPosition([node.point.points.LATITUDE, node.point.points.LONGITUDE]);
            } else {

            }
        }

        // simula live
        //props.setFlyToLeaflet(node.point.points.LATITUDE, node.point.points.LONGITUDE);

        if ( node?.point?.images) {
            let n={};
            n.info = node.point.info;
            n.images = node.point.images;
            setImagePoint(n);
        }

    }, [props.singleSnData]);


    useEffect(() => {
        setGradiLat(Math.floor(node.point.points.LATITUDE));
        setMinutiLat(Math.floor((node.point.points.LATITUDE - gradiLat)*60));
        setSecondiLat((((node.point.points.LATITUDE - gradiLat)*60) - minutiLat)*60);

        setGradiLon(Math.floor(node.point.points.LONGITUDE));
        setMinutiLon(Math.floor((node.point.points.LONGITUDE - gradiLon)*60));
        setSecondiLon((((node.point.points.LONGITUDE - gradiLon)*60) - minutiLon)*60);
    }, [props.singleSnData]);

    function generateUniqueId() {
        return Math.random().toString(36).substr(2, 9); // Genera un ID univoco
      }

      useEffect(() => {
        // Funzione di cleanup chiamata solo quando il componente viene smontato
        return () => {
          props.toggleFollowFalse(props.ALIAS);
        };
      }, []); // Dipendenze vuote: effetto eseguito solo al montaggio e smontaggio

      //console.log("LOG INFOBOX...");

    return (
                <div className={"d-flex flex-column contenitoreInfobox item"}>
                    
                    <div className={"d-flex flex-column InfoBox " + classStatePanel} >
                        
                        <div className={"d-flex flex-row justify-center-content title "  + linkStatus} onClick={toggleTrueFalse} >
                            <Infoboxtitle pdv={pdv} userTipo={userTipo} htkBackend={props.htkBackend} />
                            <div className="d-flex align-items-center flex-center ml-auto p-2">
                                {   
                                    node?.point?.video ?
                                    <span className="popuptext-pc-new" id="myPopup-pc"> 
                                        <img src="./icons/iconaRegistrazioneVideo.gif" className="iconRec-pc-new" alt="Mia Immagine" />
                                    </span>
                                     : <></>
                                } 
                                {   
                                    node?.point?.images ?
                                    <span className="popuptext2-pc" id="myPopup-pc"> 
                                        <img src="./icons/iconaImageRec.webp" className="iconRec2-pc" alt="Mia Immagine" />
                                    </span>
                                    : <></>
                                } 
                            </div>
                            <div className="d-flex align-items-center ml-auto p-2">
                                <i className={"d-flex ms-auto align-item-center fas fa-2x " + iconLinkStatus}></i>
                            </div>
                        </div>

                        <div className="information d-flex flex-center w-100">
                            
                            <div className="align-self-center">Live <Moment
                                interval={1000}
                                parse="YYYY-MM-DD HH:mm:ss"
                                date={dateTimeStamp}
                                fromNow
                            />
                            </div>
                        </div>

                        <div className="d-flex flex-row w-100 flex-center">

                            {/*<button className={"w-25 flex-fill m-1 btnAction" + (photoOpen ? " toogleTrue " : "")} onClick={tooglePhotoPanel}>Photo</button>*/}

                            <button 
                                className={"w-25 flex-fill m-1 btnAction" + (props.infoboxStatesPhoto[props.ALIAS] ? " toogleTrue " : "")} 
                                onClick={() => {
                                    props.togglePhoto(props.ALIAS)
                                }}
                            >
                                    Photo
                            </button>

                            <button
                                className={
                                    "w-25 flex-fill m-1 btnAction" +
                                    (props.infoboxStates[props.ALIAS] ? " toogleTrue " : "")
                                }
                                disabled={
                                    Object.entries(props.infoboxStates).some(
                                    ([key, value]) => key !== props.ALIAS && value === true
                                    )
                                }
                                onClick={() => {
                                    props.toggleFollow(props.ALIAS);
                                    // props.setFlyTo(previousFlyTo => !previousFlyTo);
                                }}
                            >
                                Follow
                            </button>

                            {
                            serialiStreaming.includes(seriale) && (
                                    linkStatusStreaming === "CONNECTED" ? (
                                        <button className={`w-25 flex-fill m-1 btnAction ${infoboxStatesStreaming[props.ALIAS] ? 'toogleTrue' : ''}`} 
                                                onClick={() => {
                                                    props.toggleStreaming(props.ALIAS);
                                                    setIsPressedStreaming(!isPressedStreaming);
                                                }}
                                        >
                                            Streaming
                                        </button>
                                    ) : (
                                        <button className="w-25 flex-fill m-1 btnAction" disabled>
                                        Streaming
                                        </button>
                                        /*<button className={`w-25 flex-fill m-1 btnAction ${infoboxStatesStreaming[props.ALIAS] ? 'toogleTrue' : ''}`} onClick={() => {props.toggleStreaming(props.ALIAS)}}>
                                        Streaming
                                        </button>*/
                                    )
                                )
                            }
                            

                        </div>


                        <div className={props.infoboxStatesPhoto[props.ALIAS] ? " contFoto" : " d-none contFoto"}>
                            <div className="">
                                <BigMedia pic={bigMediaPic} typeBigImage={typeBigImage} eventClosePreview={_eventClosePreview} />
                                <Media typeImage={typeImage} pic={imagePoint} openPopImage={openImagePop}  />
                            </div>
                        </div>
                        
                        <div className="divider mt-1 font-24"></div>

                        {   
                            serialiStreaming.includes(seriale) && linkStatusStreaming === "CONNECTED" && infoboxStatesStreaming[props.ALIAS] &&

                            // player hls per PC

                            <div className="videoStreamingContainer">

                                <VideoPlayerHls
                                    videoStreaming={streamingH265.includes(seriale) ? videoStreamingH265 : videoStreamingH264} 
                                    nomeDispositivo={seriale} 
                                    videoStreamingName={videoStreamingName} 
                                    //infoBoxId={infoBoxId} 
                                    //videoPlayerKey={videoPlayerKey}
                                />

                            </div>
                        
                        } 

                        {      
                            !infoboxStatesStreaming[props.ALIAS] &&
                        <>
                        <div className="informationLat d-flex flex-row mt-2">
                            <p className="labelInfoBox divInfoBoxElements1">Latitude</p>
                            <p className="font-24 divInfoBoxElements2">{gradiLat.toFixed(0)}°</p>
                            <p className="font-24 divInfoBoxElements3">{minutiLat.toFixed(0)}'</p>
                            <p className="font-24 divInfoBoxElements4">{secondiLat.toFixed(3)}''</p>
                        </div>

                        <div className="informationLon d-flex flex-row">
                            <p className="labelInfoBox divInfoBoxElements1">Longitude</p>
                            <p className="font-24 divInfoBoxElements2">{gradiLon.toFixed(0)}°</p>
                            <p className="font-24 divInfoBoxElements3">{minutiLon.toFixed(0)}'</p>
                            <p className="font-24 divInfoBoxElements4">{secondiLon.toFixed(3)}''</p>
                        </div>

                        <div className="information d-flex flex-row justify-content-between">
                            <div className="d-flex flex-column">
                                <div className="labelInfoBox divInfoBoxElements">Heading </div><div className="font-24 divInfoBoxElements"> {convertedAngle && convertedAngle.toFixed(3)}</div>
                            </div>
                            {
                                userTipo!=1 ?
                                    (<div className="d-flex flex-column">
                                        <div className="labelInfoBox divInfoBoxElements">Altitude </div><div className="font-24 divInfoBoxElements">{node.point.points.ALTITUDE.toFixed(2)}</div>
                                    </div>)
                                    : (<div/>)
                            }
                            <div className="d-flex flex-column">
                                <div className="labelInfoBox divInfoBoxElements">Speed </div><div className="font-24 divInfoBoxElements">{(node.point.points.SPEED*3.6).toFixed(0) < 5 ? "0" : (node.point.points.SPEED*3.6).toFixed(0)} km/h</div>
                            </div>
                            <div className="d-flex flex-column">
                                <div className="labelInfoBox divInfoBoxElements">Speed </div><div className="font-24 divInfoBoxElements">{(node.point.points.SPEED*3.6).toFixed(0) < 5 ? "0" : (node.point.points.SPEED*1.944).toFixed(0)} kn</div>
                            </div>
                        </div>
                        </>
                        }

                        <div className="information" >

                            <div className="divider mt-2 mb-2"></div>

                            <ConnectionState 
                                ALIAS={props.ALIAS}
                                userTipo={userTipo} 
                                node={node} 
                                setLinkStatus = {changeLinkStatus} 
                                setLinkStatusStreaming = {changeLinkStatusStreaming} 
                            />
                        </div>

                    </div>

                </div>
    )
}

