import React, { useEffect } from 'react';

export default function FlyObjectGoogle3D(props) {
    const { rilevazioni, SN } = props;

    useEffect(() => {
        const mappaEarth = document.querySelector('gmp-map-3d');

        if (!mappaEarth) return;

        // Funzione per aggiornare la mappa con polilinee che rappresentano i punti e la connessione tra loro
        const updateMapObjects = () => {
            // Identificativo unico per il seriale del dispositivo
            const serialeId = SN;

            // Rimuovi polilinee precedenti associate al serialeId
            document.querySelectorAll(`.custom-polyline[data-seriale-id="${serialeId}"]`).forEach(el => el.remove());

            // Array di coordinate per la polilinea principale (connessione tra i punti)
            const mainPolylineCoordinates = [];

            // Determina l'ultimo punto
            const lastIndex = rilevazioni.length - 1;

            // Itera su ogni rilevazione per creare polilinee che rappresentano i punti
            rilevazioni.forEach((singlePoint, index) => {

                if (index === lastIndex) {
                    //console.log(`Marker Direction Leaflet : ${singlePoint.Direction}`);
                }else{

                    const punto = singlePoint.point.points;

                    // Calcola i vertici per una polilinea che simula un punto visivamente
                    const distance = 0.000001;
                    const north = { lat: punto.LATITUDE + distance, lng: punto.LONGITUDE, altitude: punto.ALTITUDE };
                    const east = { lat: punto.LATITUDE, lng: punto.LONGITUDE + distance, altitude: punto.ALTITUDE };
                    const south = { lat: punto.LATITUDE - distance, lng: punto.LONGITUDE, altitude: punto.ALTITUDE };
                    const west = { lat: punto.LATITUDE, lng: punto.LONGITUDE - distance, altitude: punto.ALTITUDE };

                    // Crea la polilinea per simulare il punto
                    const polylinePoint = document.createElement('gmp-polyline-3d');
                    polylinePoint.className = 'custom-polyline';
                    polylinePoint.setAttribute('data-seriale-id', serialeId);
                    polylinePoint.setAttribute('altitude-mode', 'absolute');
                    polylinePoint.setAttribute('stroke-color', 'white');

                    // Se è l'ultimo punto, aumentiamo lo stroke-width
                    if (index === lastIndex - 1) {
                        polylinePoint.setAttribute('stroke-color', 'yellow');
                        polylinePoint.setAttribute('stroke-width', '25'); // Dimensione maggiore per l'ultimo punto
                    } else {
                        polylinePoint.setAttribute('stroke-color', 'white');
                        polylinePoint.setAttribute('stroke-width', '15'); // Dimensione normale per gli altri punti
                    }

                    polylinePoint.coordinates = [
                        { lat: north.lat, lng: north.lng, altitude: north.altitude },
                        { lat: east.lat, lng: east.lng, altitude: east.altitude },
                        { lat: south.lat, lng: south.lng, altitude: south.altitude },
                        { lat: west.lat, lng: west.lng, altitude: west.altitude },
                        { lat: north.lat, lng: north.lng, altitude: north.altitude }
                    ];

                    // Aggiungi la polilinea del punto alla mappa
                    mappaEarth.appendChild(polylinePoint);

                    // Aggiungi il punto attuale alla polilinea principale
                    mainPolylineCoordinates.push({ lat: punto.LATITUDE, lng: punto.LONGITUDE, altitude: punto.ALTITUDE });
                }
            });

            // Creazione della polilinea principale tra i punti
            if (mainPolylineCoordinates.length > 1) {
                const mainPolyline = document.createElement('gmp-polyline-3d');
                mainPolyline.className = 'custom-polyline';
                mainPolyline.setAttribute('data-seriale-id', serialeId);
                mainPolyline.setAttribute('altitude-mode', 'absolute');
                mainPolyline.setAttribute('stroke-color', 'red'); // Differenziazione visiva per la polilinea principale
                mainPolyline.setAttribute('stroke-width', '10');
                mainPolyline.coordinates = mainPolylineCoordinates;

                // Aggiungi la polilinea principale alla mappa
                mappaEarth.appendChild(mainPolyline);
            }
            
        };

        updateMapObjects();

        // Cleanup: rimuove i punti e le polilinee quando il componente viene smontato
        return () => {
            document.querySelectorAll(`.custom-polyline[data-seriale-id="${SN}"]`).forEach(el => el.remove());
        };
    }, [rilevazioni, SN]); // Rielabora quando rilevazioni o seriale cambiano

    return null; // Non restituisce alcun contenuto JSX
}