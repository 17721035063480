import React from "react";
import ConnectionState from "./ConnectionState";
import Telemetria from "./telemetria";
import Media from './Media';
import {useState, useEffect, useRef} from "react";
import {useMap} from "react-leaflet";
import "../InfoBox.css";
import Moment from 'react-moment';
import 'moment/locale/it';
import useInterval from "../hooks/useInterval";
import axios from 'axios';
import Infoboxtitle from "./infoboxtitle";
import BigMedia from "./bigMedia";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import VideoPlayerHls from "./VideoPlayerHls";

export default function InfoBoxWindy(props) {

    const { toggleTrackVisibility } = props;

    const [infoBoxId, setInfoboxId] = useState(props.index);
    const videoPlayerKey = props.videoPlayerKey || 0; // Chiave unica per forzare il re-render della componente VideoPlayerStreamingTest

    //const [videoStreamingContainer, setVideoStreamingContainer] = useState("videoStreamingContainer");
    const [videoStreamingH265, setVideoStreamingH265] = useState(props.urlVideoStreamingH265);
    const [videoStreamingH264, setVideoStreamingH264] = useState(props.urlVideoStreamingH264);

    const [node, setNode] = useState(props.singleSnData);
    const [videoStreamingName, setVideoStreamingName] = useState(node?.point?.info?.MISSION_NUM);

    const [imagePoint, setImagePoint] = useState(null);
    const [isToggled, setToggled] = useState(false);
    const [iconLinkStatus, setIconLinkStatus] = useState("fa-chevron-down");
    const [classStatePanel, setClassStatePanel] = useState("");

    const [linkStatus, changeLinkStatus] = useState("SEARCHING");
    const [linkStatusStreaming, changeLinkStatusStreaming] = useState("SEARCHING");
    const [pdv, setPdv] = useState(null);
    const [allData, setAllData] = useState(props.allData);
    const [seriale, setSeriale] = useState("Seriali ...");
    const serialiStreaming = props.serialiStreaming;
    const streamingH265 = props.streamingH265;
    const streamingH264 = props.streamingH264;

    const [imageData, setImageData] = useState(null);
    const [lastFlyToPosition, setLastFlyToPosition] = useState();
    const [userTipo, setUserTipo] = useState(props.userTipo);
    const [bigMediaPic, setBigMediaPic] = useState(null);
    const [typeBigImage, setTypeBigImage] = useState("IR_IMAGE");
    const [typeImage, setTypeImage] = useState("IR_IMAGE");
    const [videoOpen, setVideoOpen] = useState(false);
    const [photoOpen, setPhotoOpen] = useState(false);

    const [gradiLat, setGradiLat] = useState(node.point.points.LATITUDE);
    const [minutiLat, setMinutiLat] = useState(node.point.points.LATITUDE);
    const [secondiLat, setSecondiLat] = useState(node.point.points.LATITUDE);

    const [gradiLon, setGradiLon] = useState(node.point.points.LONGITUDE);
    const [minutiLon, setMinutiLon] = useState(node.point.points.LONGITUDE);
    const [secondiLon, setSecondiLon] = useState(node.point.points.LONGITUDE);

    const [infoboxStatesStreaming, setInfoboxStatesStreaming] = useState(props.infoboxStatesStreaming);

    const [prevHeading, setPrevHeading] = useState(null);

    const [prevLat, setPrevLat] = useState(null);
    const [prevLon, setPrevLon] = useState(null);
    const [prevAlt, setPrevAlt] = useState(null);
    const [prevPKTNUM, setPrevPKTNUM] = useState(null);

    const [rilevazioni, setRilevazioni] = useState(props.rilevazioni);

    const polylinesRef = useRef([]); // Ref per le polylines
    const pointsRef = useRef([]); // Ref per i punti

    const isAnimating = useRef(false); // Per tracciare se è in corso un'animazione
    const animationFrameId = useRef(null); // Per salvare l'ID dell'animazione corrente

    //const map = useMap();

    const [convertedAngle, setConvertedAngle] = useState(null);

    const flyZoomOptions = {
        animate: true, duration: 0.5
    }

    const apiClient = axios.create({
        baseURL: props.htkBackend,
        withCredentials: true,
    })

    useEffect(() => {
        setRilevazioni(props.rilevazioni);

        // console.log("RILEVAZIONE PKT_NUM : " , props.rilevazioni[props.rilevazioni.length - 1].point.info.PKT_NUM);

        // console.log("RILEVAZIONI : " , props.rilevazioni);

      }, [props.rilevazioni]);

    useEffect(() => {
        setInfoboxId(infoBoxId);
      }, [infoBoxId]);

      useEffect(() => {
        setInfoboxStatesStreaming(props.infoboxStatesStreaming);
      }, [props.infoboxStatesStreaming]);

    useEffect(() => {
        if (linkStatusStreaming !== 'CONNECTED') {
          //setVideoOpen(false);
          props.setInfoboxStatesStreaming(prevStates => ({
            ...prevStates,
            [props.ALIAS]: false,
          }));
        }
      }, [linkStatusStreaming]);
    
    useEffect(() => {
        const iframe = document.getElementById('myIframe');
    
        if (iframe) {
          iframe.addEventListener('load', () => {
            const video = iframe.contentDocument.querySelector('video');
            if (video) {
              video.style.fontSize = '20px';
            }
          });
        }
      }, []);
    

    useEffect(() => {
        setUserTipo(props.userTipo);
    }, [props.userTipo]);

    useEffect(() => {
        setVideoStreamingName(node.point.info.MISSION_NUM);
      }, [node.point.info.MISSION_NUM]);

    /*
    useEffect(() => {
    }, [props.allData])
    */

    var moment = require('moment-timezone');
    //const dateTimeStamp = moment.utc(node.point.time.YEAR + "-" + node.point.time.MONTH + "-" + node.point.time.DAY + " " + node.point.time.HOUR + ":" + node.point.time.MINUTE + ":" + node.point.time.SECOND).local();
    const dateTimeStamp = moment.utc(`${node.point.time.YEAR}-${node.point.time.MONTH}-${node.point.time.DAY} ${node.point.time.HOUR}:${node.point.time.MINUTE}:${node.point.time.SECOND}`, 'YYYY-MM-DD HH:mm:ss').local();

    const toggleTrueFalse = () => {
        setToggled(!isToggled);
        isToggled ? setClassStatePanel("") : setClassStatePanel("InfoBox-Close");
        
        if (isToggled) {
            setClassStatePanel("");
            setTimeout(() => {
                setIconLinkStatus("fa-chevron-down");
            }, 600);
        } else {
            setClassStatePanel("InfoBox-Close");
            setTimeout(() => {
                setIconLinkStatus("fa-chevron-up");
            }, 1100);
        }
    
    };

    const tooglePhotoPanel = () => {
        setPhotoOpen(!photoOpen);

    }

    useInterval(() => {checkPDV(node.SN)}, 1000);

    const checkPDV = async(sn) => {

        apiClient.get(props.htkBackend + "/datalive/PDV/" + sn)
            .then((response) => {
                if (response.status == 200) {
                    if ( response.data.status == "noPDV") {

                    }  else {
                        setPdv(response.data);
                        setSeriale(response.data.seriale.seriale);
                    }

                }
            })
    };

    function normalizeAngle(angle) {
        return (angle % 360 + 360) % 360;
    }
    
    function calculateDeltaHeading(startHeading, heading) {
        const normalizedStartHeading = normalizeAngle(startHeading);
        const normalizedHeading = normalizeAngle(heading);

       //console.log("heading : " , heading);
    
       //console.log("normalized heading : " , normalizedHeading);

        let deltaHeading = normalizedHeading - normalizedStartHeading;
        if (deltaHeading > 180) deltaHeading -= 360;
        if (deltaHeading < -180) deltaHeading += 360;
    
        return deltaHeading;
    }    

    // quando lascio premuto il tasto follow nel mentre l'elicottero sta volando, la visuale rimane agganciata all'elicottero
    // logica : ottieni lat, lng, altitude, range e heading dalla mappa (valori utilizzati per creare lo start dell'animazione)
    // aggiornamento dati mappa : center (imposta anche heading se vuoi l'effetto navigatore)
    function flyTo(lat, lng, altitude, heading) {
        const mappaEarth = document.querySelector('gmp-map-3d');
        
        if (!mappaEarth) return;
    
        // Ottieni la posizione attuale della mappa e altri parametri
        const startLat = mappaEarth.center.lat;
        const startLng = mappaEarth.center.lng;
        const startZoom = mappaEarth.range; // Assumiamo che 'range' rappresenti lo zoom attuale
        const startHeading = mappaEarth.heading || 0; // Prende l'heading attuale della mappa o 0 se non definito
    
        const duration = 600; // Durata dell'animazione in millisecondi
        let startTime = null;
    
        // Calcolo della differenza angolare minimizzando la rotazione
        // let deltaHeading = heading - startHeading;
        // if (deltaHeading > 180) deltaHeading -= 360; // Ruota in senso opposto se supera 180°
        // if (deltaHeading < -180) deltaHeading += 360; // Ruota in senso opposto se inferiore a -180°

        let deltaHeading = calculateDeltaHeading(startHeading, heading);
    
        // Funzione di animazione per interpolare i valori
        function animate(timestamp) {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / duration, 1); // Progressione da 0 a 1
    
            // Interpolazione lineare di latitudine, longitudine, range (zoom) e heading
            const currentLat = startLat + (lat - startLat) * progress;
            const currentLng = startLng + (lng - startLng) * progress;
            const currentRange = startZoom + (1500 - startZoom) * progress; // Valore interpolato di zoom
            
            // Calcolo dell'heading corrente basato sulla direzione più breve
            const currentHeading = startHeading + deltaHeading * progress;
    
            // Aggiornamento dei parametri della mappa
            mappaEarth.center = { lat: currentLat, lng: currentLng, altitude: altitude };
            //mappaEarth.range = currentRange;
            //mappaEarth.heading = currentHeading;
    
            // Continua l'animazione finché non raggiunge il 100%
            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        }
    
        // Avvia l'animazione
        requestAnimationFrame(animate);
    }

    function flyToNavigatorPrima(lat, lng, altitude, heading, nextPoint) {
        //console.log("FLY TO NAVIGATOR PRIMA...");

        const mappaEarth = document.querySelector('gmp-map-3d');
    
        if (!mappaEarth) return;
    
        // Ottieni la posizione attuale della mappa e altri parametri
        const startLat = mappaEarth.center.lat;
        const startLng = mappaEarth.center.lng;
        const startZoom = mappaEarth.range; // Assumiamo che 'range' rappresenti lo zoom attuale
        const startHeading = mappaEarth.heading || 0; // Prende l'heading attuale della mappa o 0 se non definito
        const startAltitude = mappaEarth.center.altitude; // Altitudine del punto di partenza
    
        // const duration = 900; // Durata dell'animazione in millisecondi  MODALITA' NAVIGATORE -> visuale terza persona
        const duration = 1000; // Durata dell'animazione in millisecondi    MODALITA' NAVIGATORE -> visuale prima persona
        let startTime = null;
    
        // Calcolo della differenza angolare minimizzando la rotazione
        let deltaHeading = calculateDeltaHeading(startHeading, heading);
    
        // Funzione di animazione per interpolare i valori
        function animate(timestamp) {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / duration, 1); // Progressione da 0 a 1
    
            // Interpolazione lineare di latitudine, longitudine, range (zoom) e heading
            const currentLat = startLat + (lat - startLat) * progress;
            const currentLng = startLng + (lng - startLng) * progress;
            const currentRange = startZoom + (0 - startZoom) * progress; // Valore interpolato di zoom
    
            // Calcolo dell'heading corrente basato sulla direzione più breve
            const currentHeading = startHeading + deltaHeading * progress;
    
            // Aggiornamento dei parametri della mappa
            mappaEarth.center = { lat: currentLat, lng: currentLng, altitude: startAltitude + (altitude - startAltitude) * progress };
            mappaEarth.range = currentRange; //decommenta per attivare la modalità navigatore sull'ultimo punto
            mappaEarth.heading = currentHeading;
            mappaEarth.tilt = 90;

            // Continua l'animazione finché non raggiunge il 100%
            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        }

        requestAnimationFrame(animate);

    }

    function flyToNavigatorTerza(lat, lng, altitude, heading, nextPoint) {
        //console.log("FLY TO NAVIGATOR TERZA...");

        const mappaEarth = document.querySelector('gmp-map-3d');
    
        if (!mappaEarth) return;
    
        // Ottieni la posizione attuale della mappa e altri parametri
        const startLat = mappaEarth.center.lat;
        const startLng = mappaEarth.center.lng;
        const startLatPolyline = prevLat;
        const startLngPolyline = prevLon;
        //const startZoom = mappaEarth.range; // Assumiamo che 'range' rappresenti lo zoom attuale
        const startHeading = mappaEarth.heading || 0; // Prende l'heading attuale della mappa o 0 se non definito
        const startAltitude = mappaEarth.center.altitude; // Altitudine del punto di partenza
        const startAltitudePolyline = prevAlt; // Altitudine del punto di partenza
    
        const duration = 900; // Durata dell'animazione in millisecondi  MODALITA' NAVIGATORE -> visuale terza persona
        // const duration = 1500; // Durata dell'animazione in millisecondi    MODALITA' NAVIGATORE -> visuale prima persona
        let startTime = null;
    
        // Calcolo della differenza angolare minimizzando la rotazione
        let deltaHeading = calculateDeltaHeading(startHeading, heading);
    
        // Calcola i segmenti della polyline tra il punto attuale e quello successivo
        const segmentCount = 50; // Numero di segmenti per l'animazione della polyline
        const polylineCoordinates = []; // Array per i punti della polyline
    
        for (let i = 0; i <= segmentCount; i++) {
            const progress = i / segmentCount;
    
            // Interpolazione delle coordinate del segmento
            const segmentLat = startLatPolyline + (lat - startLatPolyline) * progress;
            const segmentLng = startLngPolyline + (lng - startLngPolyline) * progress;
    
            // Interpolazione dell'altitudine tra il punto di partenza e quello successivo
            const segmentAltitude = startAltitudePolyline + (altitude - startAltitudePolyline) * progress;
    
            // polylineCoordinates.push({ lat: segmentLat, lng: segmentLng, altitude: segmentAltitude });
            if (typeof segmentLat === 'number' && typeof segmentLng === 'number' && typeof segmentAltitude === 'number') {
                //console.log("PRE ERROR...");
                polylineCoordinates.push({ lat: segmentLat, lng: segmentLng, altitude: segmentAltitude });
            }
            
        }
    
        // Creazione della polyline vuota che crescerà durante l'animazione
        let animatedPolyline = createPolyline(mappaEarth, polylineCoordinates);
    
        // Creazione del punto che si muove lungo la polyline usando la logica da te descritta
        let punto = { LATITUDE: startLatPolyline, LONGITUDE: startLngPolyline, ALTITUDE: startAltitudePolyline };
        let animatedPoint = createPointPolyline(mappaEarth, punto);
    
        // Funzione di animazione per interpolare i valori
        function animate(timestamp) {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / duration, 1); // Progressione da 0 a 1
    
            // Interpolazione lineare di latitudine, longitudine, range (zoom) e heading
            const currentLat = startLat + (lat - startLat) * progress;
            const currentLng = startLng + (lng - startLng) * progress;
            //const currentRange = startZoom + (1700 - startZoom) * progress; // Valore interpolato di zoom
    
            // Calcolo dell'heading corrente basato sulla direzione più breve
            const currentHeading = startHeading + deltaHeading * progress;
    
            // Aggiornamento dei parametri della mappa
            mappaEarth.center = { lat: currentLat, lng: currentLng, altitude: startAltitude + (altitude - startAltitude) * progress };
            mappaEarth.heading = currentHeading;
            // mappaEarth.range = 0; //decommenta per attivare la modalità navigatore sull'ultimo punto
            // mappaEarth.tilt = 90;
    
            // Aggiornamento progressivo della polyline: aggiungi progressivamente i punti
            const visibleSegmentIndex = Math.floor(progress * segmentCount);
            animatedPolyline.coordinates = polylineCoordinates.slice(0, visibleSegmentIndex + 1);
    
            // Aggiorna la posizione del punto animato
            if (visibleSegmentIndex < polylineCoordinates.length) {
                const currentPoint = polylineCoordinates[visibleSegmentIndex];
                updatePointPosition(animatedPoint, currentPoint);
            }
    
            // Continua l'animazione finché non raggiunge il 100%
            if (progress < 1) {
                requestAnimationFrame(animate);
            } else {
                // Una volta completata l'animazione, rimuovi la polyline e il punto animato
                setTimeout(() => {
                    mappaEarth.removeChild(animatedPolyline);
                    mappaEarth.removeChild(animatedPoint);
                    animatedPolyline = createPolyline(mappaEarth, polylineCoordinates);
                }, 600); // 600 millisecondi di ritardo prima di rimuovere e ricreare
            }
        }

        if(animatedPoint != null){
            // Avvia l'animazione
            requestAnimationFrame(animate);            
        }
    }
    
    // Funzione per creare e configurare una polyline
    function createPolyline(mappaEarth, polylineCoordinates) {
        const polyline = document.createElement('gmp-polyline-3d');
        polyline.className = 'custom-polyline';
        polyline.setAttribute('altitude-mode', 'absolute');
        polyline.setAttribute('stroke-color', 'red'); // Colore della polyline
        polyline.setAttribute('stroke-width', '10'); // Larghezza della polyline
        mappaEarth.appendChild(polyline);
        return polyline;
    }
    
    // Funzione per creare un punto usando una polyline, basandosi sulla logica fornita
    function createPointPolyline(mappaEarth, punto) {
        //console.log("PUNTO : " , punto);
        if(punto.LATITUDE != null && punto.LONGITUDE != null && punto.ALTITUDE != null){
            const distance = 0.000001;
            const north = { lat: punto.LATITUDE + distance, lng: punto.LONGITUDE, altitude: punto.ALTITUDE };
            const east = { lat: punto.LATITUDE, lng: punto.LONGITUDE + distance, altitude: punto.ALTITUDE };
            const south = { lat: punto.LATITUDE - distance, lng: punto.LONGITUDE, altitude: punto.ALTITUDE };
            const west = { lat: punto.LATITUDE, lng: punto.LONGITUDE - distance, altitude: punto.ALTITUDE };
        
            // Crea la polilinea per simulare il punto
            const polylinePoint = document.createElement('gmp-polyline-3d');
            polylinePoint.className = 'custom-polyline';
            polylinePoint.setAttribute('altitude-mode', 'absolute');
            polylinePoint.setAttribute('stroke-color', 'yellow');
            polylinePoint.setAttribute('stroke-width', '25'); // Dimensione del punto
        
            polylinePoint.coordinates = [
                { lat: north.lat, lng: north.lng, altitude: north.altitude },
                { lat: east.lat, lng: east.lng, altitude: east.altitude },
                { lat: south.lat, lng: south.lng, altitude: south.altitude },
                { lat: west.lat, lng: west.lng, altitude: west.altitude },
                { lat: north.lat, lng: north.lng, altitude: north.altitude }
            ];
        
            // Aggiungi la polilinea del punto alla mappa
            mappaEarth.appendChild(polylinePoint);
            return polylinePoint;
        }
        else{
            return null;
        }
    }
    
    // Funzione per aggiornare la posizione del punto
    function updatePointPosition(animatedPoint, newPosition) {
        const distance = 0.000001;
        const north = { lat: newPosition.lat + distance, lng: newPosition.lng, altitude: newPosition.altitude };
        const east = { lat: newPosition.lat, lng: newPosition.lng + distance, altitude: newPosition.altitude };
        const south = { lat: newPosition.lat - distance, lng: newPosition.lng, altitude: newPosition.altitude };
        const west = { lat: newPosition.lat, lng: newPosition.lng - distance, altitude: newPosition.altitude };
    
        animatedPoint.coordinates = [
            { lat: north.lat, lng: north.lng, altitude: north.altitude },
            { lat: east.lat, lng: east.lng, altitude: east.altitude },
            { lat: south.lat, lng: south.lng, altitude: south.altitude },
            { lat: west.lat, lng: west.lng, altitude: west.altitude },
            { lat: north.lat, lng: north.lng, altitude: north.altitude }
        ];
    }
    
    
    

    // quando premo il tasto follow, viene posizionata la visuale sull'elicottero
    // logica : ottieni lat, lng, range e heading dalla mappa (valori utilizzati per creare lo start dell'animazione)
    // aggiornamento dati mappa : center, range, tilt, heading
    function flyToFollowPrima(lat, lng, altitude, heading) {
        //console.log("FLY TO FOLLOW PRIMA PERSONA");

        const mappaEarth = document.querySelector('gmp-map-3d');
        
        if (!mappaEarth) return;
    
        // Ottieni la posizione attuale della mappa e altri parametri
        const startLat = mappaEarth.center.lat;
        const startLng = mappaEarth.center.lng;
        const startZoom = mappaEarth.range; // Assumiamo che 'range' rappresenti lo zoom attuale
        // const startHeading = mappaEarth.heading || 0; // Prende l'heading attuale della mappa o 0 se non definito

       //console.log("prev heading : " , prevHeading);
       //console.log("start heading : " , startHeading);
       //console.log("heading : " , heading);
    
        const duration = 600; // Durata dell'animazione in millisecondi
        let startTime = null;
    
        // Calcolo della differenza angolare minimizzando la rotazione
        // let deltaHeading = heading - startHeading;
        // if (deltaHeading > 180) deltaHeading -= 360; // Ruota in senso opposto se supera 180°
        // if (deltaHeading < -180) deltaHeading += 360; // Ruota in senso opposto se inferiore a -180°

        // let deltaHeading = calculateDeltaHeading(startHeading, heading);
    
        // Funzione di animazione per interpolare i valori
        function animate(timestamp) {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / duration, 1); // Progressione da 0 a 1
    
            // Interpolazione lineare di latitudine, longitudine, range (zoom) e heading
            const currentLat = startLat + (lat - startLat) * progress;
            const currentLng = startLng + (lng - startLng) * progress;
            const currentRange = startZoom + (0 - startZoom) * progress; // Valore interpolato di zoom // 1700 è il targetRange
            
            // Calcolo dell'heading corrente basato sulla direzione più breve
            // const currentHeading = startHeading + deltaHeading * progress;
    
            // Aggiornamento dei parametri della mappa
            mappaEarth.center = { lat: currentLat, lng: currentLng, altitude: altitude };
            mappaEarth.range = currentRange;
            // mappaEarth.heading = currentHeading;
            // mappaEarth.tilt = 90;
    
            // Continua l'animazione finché non raggiunge il 100%
            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        }
    
        // Avvia l'animazione
        requestAnimationFrame(animate);
    }
    
    function flyToFollowTerza(lat, lng, altitude, heading) {
        //console.log("FLY TO FOLLOW TERZA PERSONA");

        const mappaEarth = document.querySelector('gmp-map-3d');
        
        if (!mappaEarth) return;
    
        // Ottieni la posizione attuale della mappa e altri parametri
        const startLat = mappaEarth.center.lat;
        const startLng = mappaEarth.center.lng;
        const startZoom = mappaEarth.range; // Assumiamo che 'range' rappresenti lo zoom attuale
        const startHeading = mappaEarth.heading || 0; // Prende l'heading attuale della mappa o 0 se non definito

       //console.log("prev heading : " , prevHeading);
       //console.log("start heading : " , startHeading);
       //console.log("heading : " , heading);
    
        const duration = 600; // Durata dell'animazione in millisecondi
        let startTime = null;
    
        // Calcolo della differenza angolare minimizzando la rotazione
        // let deltaHeading = heading - startHeading;
        // if (deltaHeading > 180) deltaHeading -= 360; // Ruota in senso opposto se supera 180°
        // if (deltaHeading < -180) deltaHeading += 360; // Ruota in senso opposto se inferiore a -180°

        let deltaHeading = calculateDeltaHeading(startHeading, heading);
    
        // Funzione di animazione per interpolare i valori
        function animate(timestamp) {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / duration, 1); // Progressione da 0 a 1
    
            // Interpolazione lineare di latitudine, longitudine, range (zoom) e heading
            const currentLat = startLat + (lat - startLat) * progress;
            const currentLng = startLng + (lng - startLng) * progress;
            const currentRange = startZoom + (1700 - startZoom) * progress; // Valore interpolato di zoom // 1700 è il targetRange
            
            // Calcolo dell'heading corrente basato sulla direzione più breve
            const currentHeading = startHeading + deltaHeading * progress;
    
            // Aggiornamento dei parametri della mappa
            mappaEarth.center = { lat: currentLat, lng: currentLng, altitude: altitude };
            mappaEarth.range = currentRange;
            mappaEarth.tilt = 70;
            mappaEarth.heading = currentHeading;
    
            // Continua l'animazione finché non raggiunge il 100%
            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        }
    
        // Avvia l'animazione
        requestAnimationFrame(animate);
    }
      

    useEffect(() => {
        let imageData = allData.filter((singleNode) => {
            if ( singleNode.point.hasOwnProperty("images")) {
                if ( singleNode.point.images.RGB_IMAGE != "") {
                    return true
                } else {
                    return false;
                }
            }
        });
        imageData = imageData.slice(0, 4);
        setImageData(imageData);
    }, [props.allData]);

    /*
    useEffect( () => {
        setAllData(props.allData);
    }, [props.allData]);
    */

    useEffect( () => {
        if ( props.infoboxStates[props.ALIAS]) {
            //map.flyTo([node.point.points.LATITUDE, node.point.points.LONGITUDE],map.getZoom(), flyZoomOptions);
            //console.log("EFFETTO FLY TO");
            //props.setFlyToGoogle3DFollow(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.point.points.HEADING);
            flyToFollowPrima(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.Direction);
            // console.log("contenuto node.Direction 3D: " , node.Direction);
            // console.log("contenuto node.point.points.HEADING 3D: " , node.Direction);
        }
    }, [props.infoboxStates[props.ALIAS]]);

    useEffect( () => {
        if ( props.infoboxStatesSimu[props.ALIAS]) {
            //map.flyTo([node.point.points.LATITUDE, node.point.points.LONGITUDE],map.getZoom(), flyZoomOptions);
            //console.log("EFFETTO FLY TO");
            //props.setFlyToGoogle3DFollow(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.point.points.HEADING);
            flyToFollowTerza(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.Direction);
            // console.log("contenuto node.Direction 3D: " , node.Direction);
            // console.log("contenuto node.point.points.HEADING 3D: " , node.Direction);
        }
    }, [props.infoboxStatesSimu[props.ALIAS]]);

    // useEffect( () => {
    //     if ( props.infoboxStatesSimu[props.ALIAS]) {
    //         //map.flyTo([node.point.points.LATITUDE, node.point.points.LONGITUDE],map.getZoom(), flyZoomOptions);
    //         //console.log("EFFETTO FLY TO");
    //         //props.setFlyToGoogle3DFollow(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.point.points.HEADING);
    //         flyToFollow(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.Direction);
    //         // console.log("contenuto node.Direction 3D: " , node.Direction);
    //         // console.log("contenuto node.point.points.HEADING 3D: " , node.Direction);
    //     }
    // }, [props.infoboxStatesSimu[props.ALIAS]]);

    useEffect( () => {
        props.setInfoboxStates(props.infoboxStates);
        props.setInfoboxStatesSimu(props.infoboxStatesSimu);
        props.setInfoboxStatesPhoto(props.infoboxStatesPhoto);
        props.setInfoboxStatesStreaming(props.infoboxStatesStreaming);
    }, [props.infoboxStates,props.infoboxStatesSimu,props.infoboxStatesPhoto,props.infoboxStatesStreaming]);
/*
    useEffect( () => {
        props.setInfoboxStatesPhoto(props.infoboxStatesPhoto);
    }, [props.infoboxStatesPhoto]);

    useEffect( () => {
        props.setInfoboxStatesStreaming(props.infoboxStatesStreaming);
    }, [props.infoboxStatesStreaming]);
*/

    function flyToCentering()  {
        //map.flyTo([node.point.points.LATITUDE, node.point.points.LONGITUDE],map.getZoom(), flyZoomOptions)
    }

    const openImagePop = (nodeImage, typeImage) => {
        setBigMediaPic(nodeImage);
        setTypeBigImage(typeImage);

    }

    /*
    const toogleVideoPanel = () => {
        setVideoOpen(!videoOpen);
    }
    */
    const _eventClosePreview = () => {
        setBigMediaPic(null);
    }

    const _fnTitleInfoBox2 = () => {
        switch (userTipo) {
            case 1:
                return (
                    <h4>{pdv?.alias}</h4>
                )
            case 2:
                return (
                    <div className="font-23">{pdv?.seriale.seriale}/{pdv?.marca}/{pdv?.alias}</div>
                )
            case 3:
                return (
                    <h4>{pdv?.marca}</h4>
                )
            default:
                return (
                    <div>Loading ...</div>
                )
        }
    } ;

    useEffect( () => {
        setNode(props.singleSnData);

        if(node.Direction < 0){
            setConvertedAngle(node.Direction + 360);
        }
        else{
            setConvertedAngle(node.Direction);
        }
        let diffLat = 9999;
        let diffLon = 9999;

        if ( lastFlyToPosition) {
            diffLat = Math.abs(lastFlyToPosition[0]-node.point.points.LATITUDE);
            diffLon = Math.abs(lastFlyToPosition[1]-node.point.points.LONGITUDE);
        }

        if ( props.infoboxStates[props.ALIAS]) {
           //console.log("bottone follow in questione su true...");

           flyToNavigatorPrima(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.Direction);

            if ( diffLat > 0.0001  || diffLon > 0.0001) {
                if (prevHeading !== null) {
                //map.flyTo([node.point.points.LATITUDE, node.point.points.LONGITUDE],map.getZoom(), flyZoomOptions);
                
                //props.setFlyToGoogle3D(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.point.points.HEADING);
                    //flyToNavigator(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.Direction);
                    // flyTo(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, prevHeading);
                }
                setLastFlyToPosition([node.point.points.LATITUDE, node.point.points.LONGITUDE]);
            } else {

            }
            // console.log("node : " , node);
        }

        if ( props.infoboxStatesSimu[props.ALIAS]) {
            //console.log("bottone follow in questione su true...");
 
            flyToNavigatorTerza(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.Direction);
 
             if ( diffLat > 0.0001  || diffLon > 0.0001) {
                 if (prevHeading !== null) {
                 //map.flyTo([node.point.points.LATITUDE, node.point.points.LONGITUDE],map.getZoom(), flyZoomOptions);
                 
                 //props.setFlyToGoogle3D(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.point.points.HEADING);
                     //flyToNavigator(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.Direction);
                     // flyTo(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, prevHeading);
                 }
                 setLastFlyToPosition([node.point.points.LATITUDE, node.point.points.LONGITUDE]);
             } else {
 
             }
             // console.log("node : " , node);
         }

        // simula live
        //props.setFlyToGoogle3D(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.point.points.HEADING);

        if ( node?.point?.images) {
            let n={};
            n.info = node.point.info;
            n.images = node.point.images;
            setImagePoint(n);
        }

        setPrevHeading(node.point.points.HEADING);
        setPrevLat(node.point.points.LATITUDE);
        setPrevLon(node.point.points.LONGITUDE);
        setPrevAlt(node.point.points.ALTITUDE);
        setPrevPKTNUM(node.point.info.PKT_NUM);

        // console.log("PKT NUM : " , node.point.info.PKT_NUM);

        // console.log("RILEVAZIONE PKT_NUM : " , props.rilevazioni[props.rilevazioni.length - 1].point.info.PKT_NUM);

        // console.log("RILEVAZIONI : " , props.rilevazioni);

    }, [props.singleSnData]);

    /*
    useEffect(() => {
        const mappaEarth = document.querySelector('gmp-map-3d');
    
        if (!mappaEarth) return;
    
        // Creazione di una nuova polyline e punto se ci sono più di una rilevazione
        if (props.rilevazioni && props.rilevazioni.length > 1) {
            const lastIndex = props.rilevazioni.length - 2;
            const prevPoint = props.rilevazioni[lastIndex - 1].point.points;
            const currentPoint = props.rilevazioni[lastIndex].point.points;
    
            const polylineCoordinates = [
                { lat: prevPoint.LATITUDE, lng: prevPoint.LONGITUDE, altitude: prevPoint.ALTITUDE },
                { lat: currentPoint.LATITUDE, lng: currentPoint.LONGITUDE, altitude: currentPoint.ALTITUDE },
            ];
    
            // Crea una nuova polyline tra il punto precedente e quello corrente
            const newPolyline = createPolyline2(mappaEarth, polylineCoordinates);
    
            // Crea un nuovo punto basato sull'ultimo rilevamento
            const punto = {
                LATITUDE: currentPoint.LATITUDE,
                LONGITUDE: currentPoint.LONGITUDE,
                ALTITUDE: currentPoint.ALTITUDE,
            };
    
            // Differenziazione del punto per l'ultimo elemento
            const isLastPoint = props.rilevazioni.length - 1 === lastIndex;
            const newPoint = isLastPoint 
                ? createHighlightedPoint(mappaEarth, punto) 
                : createStandardPoint(mappaEarth, punto);
    
            // Aggiungi la nuova polyline e punto agli array di riferimento
            polylinesRef.current.push(newPolyline);
            pointsRef.current.push(newPoint);
    
            // Mantieni solo le ultime 30 linee e punti, rimuovendo i più vecchi
            if (polylinesRef.current.length > 30) {
                const oldestPolyline = polylinesRef.current.shift();
                if (mappaEarth.contains(oldestPolyline)) {
                    mappaEarth.removeChild(oldestPolyline);
                }
            }
    
            if (pointsRef.current.length > 30) {
                const oldestPoint = pointsRef.current.shift();
                if (mappaEarth.contains(oldestPoint)) {
                    mappaEarth.removeChild(oldestPoint);
                }
            }
        }
    
        // Cleanup: rimuovi solo le linee e punti che non sono più necessari
        return () => {
            const activePolylines = props.rilevazioni.slice(-30).map((_, index, arr) => {
                if (index > 0) {
                    const prevPoint = arr[index - 1].point.points;
                    const currentPoint = arr[index].point.points;
    
                    return createPolyline2(mappaEarth, [
                        { lat: prevPoint.LATITUDE, lng: prevPoint.LONGITUDE, altitude: prevPoint.ALTITUDE },
                        { lat: currentPoint.LATITUDE, lng: currentPoint.LONGITUDE, altitude: currentPoint.ALTITUDE },
                    ]);
                }
                return null;
            }).filter(Boolean);
    
            polylinesRef.current.forEach((polyline) => {
                if (!activePolylines.includes(polyline) && mappaEarth.contains(polyline)) {
                    mappaEarth.removeChild(polyline);
                }
            });
    
            const activePoints = props.rilevazioni.slice(-30).map((rilevazione, index) => {
                const punto = rilevazione.point.points;
                const isLastPoint = index === props.rilevazioni.length - 1;
                return isLastPoint ? createHighlightedPoint(mappaEarth, punto) : createStandardPoint(mappaEarth, punto);
            });
    
            pointsRef.current.forEach((point) => {
                if (!activePoints.includes(point) && mappaEarth.contains(point)) {
                    mappaEarth.removeChild(point);
                }
            });
    
            polylinesRef.current = activePolylines;
            pointsRef.current = activePoints;
        };
    }, [props.singleSnData, props.rilevazioni]);

    */
    
    // Funzione per creare un punto usando una polyline, basandosi sulla logica fornita
    // function createPointPolyline(mappaEarth, punto) {
    //     const distance = 0.000001;
    //     const north = { lat: punto.LATITUDE + distance, lng: punto.LONGITUDE, altitude: punto.ALTITUDE };
    //     const east = { lat: punto.LATITUDE, lng: punto.LONGITUDE + distance, altitude: punto.ALTITUDE };
    //     const south = { lat: punto.LATITUDE - distance, lng: punto.LONGITUDE, altitude: punto.ALTITUDE };
    //     const west = { lat: punto.LATITUDE, lng: punto.LONGITUDE - distance, altitude: punto.ALTITUDE };
    
    //     // Crea la polilinea per simulare il punto
    //     const polylinePoint = document.createElement('gmp-polyline-3d');
    //     polylinePoint.className = 'custom-polyline';
    //     polylinePoint.setAttribute('altitude-mode', 'absolute');
    //     polylinePoint.setAttribute('stroke-color', 'yellow');
    //     polylinePoint.setAttribute('stroke-width', '25'); // Dimensione del punto
    
    //     polylinePoint.coordinates = [
    //         { lat: north.lat, lng: north.lng, altitude: north.altitude },
    //         { lat: east.lat, lng: east.lng, altitude: east.altitude },
    //         { lat: south.lat, lng: south.lng, altitude: south.altitude },
    //         { lat: west.lat, lng: west.lng, altitude: west.altitude },
    //         { lat: north.lat, lng: north.lng, altitude: north.altitude }
    //     ];
    
    //     // Aggiungi la polilinea del punto alla mappa
    //     mappaEarth.appendChild(polylinePoint);
    //     return polylinePoint;
    // }
    
    // // Funzione per aggiornare la posizione del punto
    // function updatePointPosition(animatedPoint, newPosition) {
    //     const distance = 0.000001;
    //     const north = { lat: newPosition.lat + distance, lng: newPosition.lng, altitude: newPosition.altitude };
    //     const east = { lat: newPosition.lat, lng: newPosition.lng + distance, altitude: newPosition.altitude };
    //     const south = { lat: newPosition.lat - distance, lng: newPosition.lng, altitude: newPosition.altitude };
    //     const west = { lat: newPosition.lat, lng: newPosition.lng - distance, altitude: newPosition.altitude };
    
    //     animatedPoint.coordinates = [
    //         { lat: north.lat, lng: north.lng, altitude: north.altitude },
    //         { lat: east.lat, lng: east.lng, altitude: east.altitude },
    //         { lat: south.lat, lng: south.lng, altitude: south.altitude },
    //         { lat: west.lat, lng: west.lng, altitude: west.altitude },
    //         { lat: north.lat, lng: north.lng, altitude: north.altitude }
    //     ];
    // }


    useEffect(() => {
        setGradiLat(Math.floor(node.point.points.LATITUDE));
        setMinutiLat(Math.floor((node.point.points.LATITUDE - gradiLat)*60));
        setSecondiLat((((node.point.points.LATITUDE - gradiLat)*60) - minutiLat)*60);

        setGradiLon(Math.floor(node.point.points.LONGITUDE));
        setMinutiLon(Math.floor((node.point.points.LONGITUDE - gradiLon)*60));
        setSecondiLon((((node.point.points.LONGITUDE - gradiLon)*60) - minutiLon)*60);
    }, [props.singleSnData]);

    function generateUniqueId() {
        return Math.random().toString(36).substr(2, 9); // Genera un ID univoco
      }

    useEffect(() => {
        // Funzione di cleanup chiamata solo quando il componente viene smontato
        return () => {
            props.toggleFollowFalse(props.ALIAS);
            props.toggleFollowFalseSimu(props.ALIAS);
        };
    }, []); // Dipendenze vuote: effetto eseguito solo al montaggio e smontaggio
      

    return (
                <div className={"d-flex flex-column contenitoreInfobox item"}>
                    
                    <div className={"d-flex flex-column InfoBox " + classStatePanel} >
                        
                        <div className={"d-flex flex-row justify-center-content title "  + linkStatus} onClick={toggleTrueFalse} >
                            <Infoboxtitle pdv={pdv} userTipo={userTipo} htkBackend={props.htkBackend} />
                            <div className="d-flex align-items-center flex-center ml-auto p-2">
                                {   
                                    node?.point?.video ?
                                    <span className="popuptext-pc-new" id="myPopup-pc"> 
                                        <img src="./icons/iconaRegistrazioneVideo.gif" className="iconRec-pc-new" alt="Mia Immagine" />
                                    </span>
                                     : <></>
                                } 
                                {   
                                    node?.point?.images ?
                                    <span className="popuptext2-pc" id="myPopup-pc"> 
                                        <img src="./icons/iconaImageRec.webp" className="iconRec2-pc" alt="Mia Immagine" />
                                    </span>
                                    : <></>
                                } 
                            </div>
                            <div className="d-flex align-items-center ml-auto p-2">
                                <i className={"d-flex ms-auto align-item-center fas fa-2x " + iconLinkStatus}></i>
                            </div>
                        </div>

                        <div className="information d-flex flex-center w-100">
                            
                            <div className="align-self-center">Live <Moment
                                interval={1000}
                                parse="YYYY-MM-DD HH:mm:ss"
                                date={dateTimeStamp}
                                fromNow
                            />
                            </div>
                        </div>

                        <div className="d-flex flex-row w-100 flex-center">

                            {/*<button className={"w-25 flex-fill m-1 btnAction" + (photoOpen ? " toogleTrue " : "")} onClick={tooglePhotoPanel}>Photo</button>*/}

                            <button 
                                className={"w-25 flex-fill m-1 btnAction" + (props.infoboxStatesPhoto[props.ALIAS] ? " toogleTrue " : "")} 
                                onClick={() => {
                                    props.togglePhoto(props.ALIAS)
                                }}
                            >
                                Photo
                            </button>

                            <button
                                className={
                                    "w-25 flex-fill m-1 btnAction" +
                                    (props.infoboxStates[props.ALIAS] ? " toogleTrue " : "")
                                }
                                disabled={
                                    Object.entries(props.infoboxStates).some(
                                    ([key, value]) => key !== props.ALIAS && value === true
                                    ) ||
                                    Object.entries(props.infoboxStatesSimu).some(
                                    ([key, value]) => key !== props.ALIAS && value === true
                                    ) || props.infoboxStatesSimu[props.ALIAS]
                                }
                                onClick={() => {
                                    toggleTrackVisibility();
                                    props.toggleFollow(props.ALIAS);
                                    // flyTo(48.8566, 2.3522); // Usa i valori dinamici di props per le coordinate e zoom
                                }}
                            >
                                FollowPrima
                            </button>

                            <button
                                className={
                                    "w-25 flex-fill m-1 btnAction" +
                                    (props.infoboxStatesSimu[props.ALIAS] ? " toogleTrue " : "")
                                }
                                disabled={
                                    Object.entries(props.infoboxStates).some(
                                    ([key, value]) => key !== props.ALIAS && value === true
                                    ) ||
                                    Object.entries(props.infoboxStatesSimu).some(
                                    ([key, value]) => key !== props.ALIAS && value === true
                                    ) || props.infoboxStates[props.ALIAS]
                                }
                                onClick={() => {
                                    props.toggleFollowSimu(props.ALIAS);
                                    // flyTo(48.8566, 2.3522); // Usa i valori dinamici di props per le coordinate e zoom
                                }}
                            >
                                FollowTerza
                            </button>
                                                    

                            {serialiStreaming.includes(seriale) && (
                                linkStatusStreaming === "CONNECTED" ? (
                                    <button className={`w-25 flex-fill m-1 btnAction ${infoboxStatesStreaming[props.ALIAS] ? 'toogleTrue' : ''}`} onClick={() => {props.toggleStreaming(props.ALIAS)}}>
                                    Streaming
                                    </button>
                                ) : (
                                    <button className="w-25 flex-fill m-1 btnAction" disabled>
                                    Streaming
                                    </button>
                                )
                                )}

                        </div>


                        <div className={props.infoboxStatesPhoto[props.ALIAS] ? " contFoto" : " d-none contFoto"}>
                            <div className="">
                                <BigMedia pic={bigMediaPic} typeBigImage={typeBigImage} eventClosePreview={_eventClosePreview} />
                                <Media typeImage={typeImage} pic={imagePoint} openPopImage={openImagePop}  />
                            </div>
                        </div>
                        
                        <div className="divider mt-1 font-24"></div>

                        {   
                            serialiStreaming.includes(seriale) && linkStatusStreaming === "CONNECTED" && infoboxStatesStreaming[props.ALIAS] &&


                            // player hls per PC , windy

                            <div className="videoStreamingContainer">
                                
                                <VideoPlayerHls 
                                    videoStreaming={streamingH265.includes(seriale) ? videoStreamingH265 : videoStreamingH264} 
                                    nomeDispositivo={seriale} 
                                    videoStreamingName={videoStreamingName} 
                                    infoBoxId={infoBoxId} 
                                    videoPlayerKey={videoPlayerKey}
                                /> 

                            </div>
                        
                        } 

                        {      
                            !infoboxStatesStreaming[props.ALIAS] &&
                        <>
                        <div className="informationLat d-flex flex-row mt-2">
                            <p className="labelInfoBox divInfoBoxElements1">Latitude</p>
                            <p className="font-24 divInfoBoxElements2">{gradiLat.toFixed(0)}°</p>
                            <p className="font-24 divInfoBoxElements3">{minutiLat.toFixed(0)}'</p>
                            <p className="font-24 divInfoBoxElements4">{secondiLat.toFixed(3)}''</p>
                        </div>

                        <div className="informationLon d-flex flex-row">
                            <p className="labelInfoBox divInfoBoxElements1">Longitude</p>
                            <p className="font-24 divInfoBoxElements2">{gradiLon.toFixed(0)}°</p>
                            <p className="font-24 divInfoBoxElements3">{minutiLon.toFixed(0)}'</p>
                            <p className="font-24 divInfoBoxElements4">{secondiLon.toFixed(3)}''</p>
                        </div>

                        <div className="information d-flex flex-row justify-content-between">
                            <div className="d-flex flex-column">
                                <div className="labelInfoBox divInfoBoxElements">Heading </div><div className="font-24 divInfoBoxElements"> {convertedAngle && convertedAngle.toFixed(3)}</div>
                            </div>
                            {
                                userTipo!=1 ?
                                    (<div className="d-flex flex-column">
                                        <div className="labelInfoBox divInfoBoxElements">Altitude </div><div className="font-24 divInfoBoxElements">{node.point.points.ALTITUDE.toFixed(2)}</div>
                                    </div>)
                                    : (<div/>)
                            }
                            <div className="d-flex flex-column">
                                <div className="labelInfoBox divInfoBoxElements">Speed </div><div className="font-24 divInfoBoxElements">{(node.point.points.SPEED*3.6).toFixed(0) < 5 ? "0" : (node.point.points.SPEED*3.6).toFixed(0)} km/h</div>
                            </div>
                            <div className="d-flex flex-column">
                                <div className="labelInfoBox divInfoBoxElements">Speed </div><div className="font-24 divInfoBoxElements">{(node.point.points.SPEED*3.6).toFixed(0) < 5 ? "0" : (node.point.points.SPEED*1.944).toFixed(0)} kn</div>
                            </div>
                        </div>
                        </>
                        }

                        <div className="information" >

                            <div className="divider mt-2 mb-2"></div>

                            <ConnectionState userTipo={userTipo} node={node} setLinkStatus = {changeLinkStatus} setLinkStatusStreaming = {changeLinkStatusStreaming}/>
                        </div>

                    </div>

                </div>
    )
}

